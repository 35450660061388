// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  env:'preprod',
  entity_id:'17',
  domain:'https://www.universaltutorials.proteen.com',
  entity_name:'universaltutorials',
  googleAnalyticsID:'UA-85626973-1',
  razorPayKey:'rzp_live_6YzyMIz6sn4UCY',
  production: false,
  version:"1.0.0.3",
  resourceServerURL :"http://localhost:8081",
  social_media: {
    facebookAppId:"409029740393148",
    //facebookAppId:"448100349584536",//rahul
    linkedin: {
      client_id:"78qotif51jqmhg",
      client_secret:"CSq5KOERNkgOLIEb"
    },
    twitter: {
      app_id:'20571634',
      consumer_key:'fFXOjTFtpG2Sb12U9QhPFrN3G',
      consumer_secret:'DcZoLRCxcFx0cTGMuRyUKsBlDThlwiqlBGHljOpSCU9ZSKcuif',
      access_token:'113865030-BJquviwjA3Bd4VZKkASxwoWHmCOfnTb8Hd7gQNz1',
      token_secret:'zMKMi2ZZefVtpjKsZGRpXDOXyU5vkR1vXs9zZkJH0urYO',
    },
    instagram: {
      app_id:'324010449087440'
    }
  },
  minYear:'1980',
  minYearStudent:'2000',
  minYearParent:'1980',
  confirm: {
    username: "",
    email:"",
    phone: "",
    password:"",
    defaultPassword: "Softdel@123"
  },
  apiType:'',
  currentLoginUser: {
    user: {},
    userAttribute: {},
    sessionIdInfo: {}
  },
  assessmentAttempts:3,
  AWSConfig: {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:c260361b-a5b9-4ef0-90df-2ea82bf32e2d",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_T9UoaP1nK",
    "aws_user_pools_web_client_id": "6iblt85r1ooc416nc0ql0349ke",
    "oauth": {
        "domain": "proteen-webapptest.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://universaltutorials.proteen.com/proteen/select-role",
        "redirectSignOut": "https://universaltutorials.proteen.com/proteen/login",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"  
  },
  ApiConfig: {
    student : "https://cfp68ms7s8.execute-api.ap-south-1.amazonaws.com/test",
    admin: "https://uwf3qboa2e.execute-api.ap-south-1.amazonaws.com/test",
    parent: "https://h31dvjmvpe.execute-api.ap-south-1.amazonaws.com/test",
    settings: "https://g609hfxkz1.execute-api.ap-south-1.amazonaws.com/test",
    others: "https://g609hfxkz1.execute-api.ap-south-1.amazonaws.com/test",
    psychometric: "https://cfp68ms7s8.execute-api.ap-south-1.amazonaws.com/test",
    careerinfo: "https://cfp68ms7s8.execute-api.ap-south-1.amazonaws.com/test",
    leadsquared:"https://471ajva688.execute-api.ap-south-1.amazonaws.com/test"

  },
  // added flag to remove/hide proteen related pages from the report 05052023
  remove_proteen : false
};
