<div class="container-fluid px-0" style="height:100%">
    <mat-toolbar style="height: 10%;">
        <div class="row w-100">
            <div class="col-sm-12 text-center webkitScrollDisable logo-container">
                <!-- added partner logo on student login page 13042023 -->
                <img src="./../../assets/images/partner/universaltutorials.png" style="width: 4rem;" href="#" alt="rajhans-logo"
                    class=" cursor-pointer border-none partner-logo">
                <img src="./../../assets/logo/poweredby.png" href="#" alt="proteen-logo"
                    class="proteen-logo cursor-pointer border-none" (click)="navigateToHome()">
            </div>
        </div>
    </mat-toolbar>
    <div class="login-container signin-container" style="height: 90%;">
        <div class="row">
            <div class="col-md-7 vertical pr-0 m-hide">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide><img src="./assets/images/other/singup/New_Banner_1.png"></ng-template>
                    <ng-template carouselSlide><img src="./assets/images/other/singup/New_Banner_2.png"></ng-template>
                    <ng-template carouselSlide><img src="./assets/images/other/singup/New_Banner_3.png"></ng-template>

                </owl-carousel-o>

            </div>
            <div class="col-md-5 sign-in-box">
                <div class="ScollBar">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>